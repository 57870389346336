import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ProductLayout from "../../components/layouts/product_layout"
import {
    ProductButtons,
    ProductSubHeader,
    ProductParagraph,
    ProductScreenShot,
    ProductHeader,
    ProductUseCase,
    ProductBullets,
    ProductProcess,
} from "../../components/shared"
import ProductDemoForm from "../../components/products/product_demo_form"

export default function CampaignApprover({ location }) {
    const data = useStaticQuery(graphql`
        query {
            productsYaml(name: { eq: "campaign-approver" }) {
                title
                description
                app_cloud_link
                copy {
                    tagline
                    header1
                    header2
                    paragraph1
                    paragraph2
                    demo_message
                    use_cases {
                        title
                        problem
                        solution
                    }
                    process {
                        step
                    }
                    bullets {
                        header
                        description
                    }
                }
            }
            productLogo: file(
                relativePath: {
                    eq: "images/products/campaign-approver/logo.png"
                }
            ) {
                childImageSharp {
                    fixed(height: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            screenShots: allFile(
                filter: {
                    relativeDirectory: {
                        eq: "images/products/campaign-approver/screenshots"
                    }
                }
                sort: { fields: [name], order: ASC }
            ) {
                nodes {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            processIcons: allFile(
                filter: {
                    relativeDirectory: {
                        eq: "images/products/campaign-approver/process-icons"
                    }
                }
                sort: { fields: [name], order: ASC }
            ) {
                nodes {
                    childImageSharp {
                        fixed(height: 128, width: 128) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    `)

    const {
        productsYaml: {
            title,
            description,
            app_cloud_link,
            copy: {
                tagline,
                header1,
                header2,
                paragraph1,
                paragraph2,
                demo_message,
                use_cases,
                process,
                bullets,
            },
        },
        productLogo: {
            childImageSharp: { fixed: logoFixed },
        },
        screenShots: { nodes: screenShots },
        processIcons: { nodes: processIcons },
    } = data

    return (
        <ProductLayout
            title={title}
            description={description}
            location={location}
            tagline={tagline}
            logo={logoFixed}
            rating={0}
            ratingCount={0}
        >
            <div className="mt-8">
                <ProductParagraph content={paragraph1} />
            </div>

            <ProductScreenShot fluid={screenShots[3].childImageSharp.fluid} />

            <ProductButtons appCloudLink={app_cloud_link} />

            <div className="mt-12">
                <div className="lg:text-center">
                    <ProductHeader content={header1} />
                    <ProductSubHeader content={header2} />
                </div>
                <ProductParagraph content={paragraph2} />
            </div>

            <ProductProcess process={process} icons={processIcons} />

            <ProductUseCase
                useCase={use_cases[0]}
                screenShotFluid={screenShots[1].childImageSharp.fluid}
            />

            <div className="mt-12">
                <ProductBullets bullets={bullets} />
            </div>

            <ProductUseCase
                useCase={use_cases[1]}
                screenShotFluid={screenShots[4].childImageSharp.fluid}
            />

            <ProductDemoForm formMessage={demo_message} />
        </ProductLayout>
    )
}
